import React, { FC } from "react";
import RedditIcon from "./RedditIcon";
import { Link } from "react-router-dom";

const Header: FC = () => {
    return (
        <div className="header">
            <a
                href="https://www.jerkofftocelebs.com"
                className="header__title"
                title="The best Site for Nude Celebs!"
            >
                Check out JerkOffToCelebs.com
            </a>
            <a
                href="https://www.reddit.com/r/JerkOffToPorn/"
                className="header__reddit-logo"
                title="r/JerkOffToPorn on Reddit"
            >
                <RedditIcon />
            </a>
        </div>
    );
};

export default Header;
