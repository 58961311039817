import React, { FC, Fragment } from "react";

interface ShowIfProps {
    isVisible?: boolean;
    isHidden?: boolean;
}

const ShowIf: FC<ShowIfProps> = ({ children, isVisible, isHidden }) => {
    const visibility = getVisibility(isVisible, isHidden);
    if (visibility) return <Fragment>{children}</Fragment>;
    else return null;
};

export default ShowIf;

function getVisibility(isVisible?: boolean, isHidden?: boolean): boolean {
    if (isVisible !== undefined) return isVisible;
    else if (isHidden !== undefined) return !isHidden;
    else return true;
}
