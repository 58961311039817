import React, { FC } from "react";
import { Link } from "react-router-dom";

const CopyrightLink: FC = () => {
    return (
        <div className="copyright-link">
            <Link className="copyright-link__link" to="/copyright">
                Copyright
            </Link>
        </div>
    );
};

export default CopyrightLink;
