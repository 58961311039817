import CelebrityGalleriesState from "../state/CelebrityGalleriesState";
import { CelebrityGallery } from "../api/redditApi";
import { toLocalStorage, fromLocalStorage } from "../utilities/storage";
import { numberOfPreviousPicksToRemember } from "../constants";

export const celebrityGalleriesState = new CelebrityGalleriesState();

let lastPickedGallery: CelebrityGallery | null = null;

export function getRandomGallery(avoid: string[] = []): CelebrityGallery {
    const galleries = getAvoidedGalleries(avoid);
    const randomIndex = Math.floor(Math.random() * galleries.length);
    const picked = galleries[randomIndex];

    const pickAgain = galleries.length >= 2 && lastPickedGallery === picked;
    if (pickAgain) {
        return getRandomGallery(avoid);
    } else {
        lastPickedGallery = picked;
        return picked;
    }
}

function getAvoidedGalleries(avoid: string[]) {
    const { galleries } = celebrityGalleriesState;
    if (avoid.length === 0) return galleries;

    const avoidSet = new Set(avoid);
    const filteredGalleries = galleries.filter(gallery => {
        if (gallery.url !== null) {
            return !avoidSet.has(gallery.url.href);
        } else return true;
    });

    if (filteredGalleries.length > 0) return filteredGalleries;
    else return galleries;
}

export function pickFinalGallery(retryCount: number = 0): CelebrityGallery {
    const pickedGallery = getRandomGallery(previousPicks);

    const isPreviousPick =
        pickedGallery.url !== null &&
        previousPicks.includes(pickedGallery.url.href);

    if (isPreviousPick && retryCount < 5) {
        return pickFinalGallery(retryCount + 1);
    } else {
        savePick(pickedGallery);
        return pickedGallery;
    }
}

const storageKey = "JerkOffToCelebs:previous-picks-v1";

let previousPicks: string[] = readPreviousPicks();

function savePick(gallery: CelebrityGallery) {
    if (gallery.url !== null) {
        const { href } = gallery.url;
        if (!previousPicks.includes(href)) {
            previousPicks.unshift(gallery.url.href);
            previousPicks.splice(numberOfPreviousPicksToRemember);
        }
        toLocalStorage(storageKey, Array.from(previousPicks));
    }
}

function readPreviousPicks(): string[] {
    const stored: string[] = fromLocalStorage(storageKey) || [];
    return stored;
}
