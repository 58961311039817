const redditPostUrl =
    "https://www.reddit.com/r/JerkOffToPorn/wiki/somepage.json";

export interface CelebrityGallery {
    name: string;
    url: URL | null;
    adUrl: URL | null;
}

export async function fetchGalleries(): Promise<CelebrityGallery[]> {
    const result = await fetch(redditPostUrl);
    const json = await result.json();
    const markdown = json.data.content_md as string;

    const galleryLineRegexp = /^\*\*[0-9]{1,}\*\*/;
    const galleries = markdown
        .split("\n")
        .filter(line => line.match(galleryLineRegexp))
        .map(line => line.split("|").map(column => column.trim()))
        .map(columnsToGallery)
        .filter(a => a !== null);

    return galleries as CelebrityGallery[];
}

const columnsToGallery = ([_, column2, column3]: string[]) => {
    const [name, url] = extractNameAndUrl(column2);
    const adUrl = readUrl(column3);
    const isValidGallery = name && url;
    if (!isValidGallery) return null;
    else {
        return {
            name,
            url,
            adUrl
        };
    }
};

const extractNameAndUrl = (column: string) => {
    const galleryLinkRegexp = /\[(.*)\]\((.*)\)/;
    const match = column.match(galleryLinkRegexp);
    if (match) return [match[1], readUrl(match[2])];
    return [null, null];
};

const readUrl = (text: string) => {
    try {
        return new URL(text);
    } catch (e) {
        return null;
    }
};
