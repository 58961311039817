import React, { FC } from "react";

const CopyrightPage: FC = () => {
    return (
        <div className="copyright-page">
            <div className="copyright-page__content">
                <h2>Copyright</h2>

                <p>
                    Links that violate copyright will be removed. Send an
                    email to{" "}
                    <a href="mailto:nsfwsubreddits@gmail.com ">
                        nsfwsubreddits@gmail.com
                    </a>{" "}
                    to notify us of any copyright infringement.
                </p>
            </div>
        </div>
    );
};

export default CopyrightPage;
