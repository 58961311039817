import { observer } from "mobx-react";
import React, { FC, Fragment } from "react";
import { Link } from "react-router-dom";
import CelebrityPickerState from "../state/CelebrityPickerState";
import { plural } from "../utilities/plural";
import { getRolls, timeFromSeconds } from "../utilities/roll";

const Wait: FC<{ celebrityPicker: CelebrityPickerState }> = observer(
    ({ celebrityPicker }) => {
        const {
            secondsUntilLegalRoll: remainingTime,
            startPickerWithAds
        } = celebrityPicker;
        const { rollsLastDay, rollsLastHour } = getRolls();
        const { seconds, minutes, hours } = timeFromSeconds(remainingTime);

        return (
            <div className="wait">
                <p className="wait__paragraph">
                    You have rolled <RollsValue value={rollsLastDay} /> today
                    and <RollsValue value={rollsLastHour} /> in the last hour.
                </p>
                <p className="wait__small-text">
                    Looking for something specific? Browse the{" "}
                    <Link className="wait__link" to="/gallery-list">
                        gallery list
                    </Link>{" "}
                    yourself.
                </p>
            </div>
        );
    }
);

export default Wait;

const RollsValue: FC<{ value: number }> = ({ value }) => {
    return (
        <span className="wait__time">
            {value} {plural("time", value)}
        </span>
    );
};

const WaitTime: FC<{ unit: string; amount: number }> = ({ unit, amount }) => {
    if (unit !== "second" && amount <= 0) return null;

    const postfix = (() => {
        if (unit === "minute") return " and ";
        else if (unit === "hour") return ", ";
        else if (unit === "second") return " ";
        else return "";
    })();

    return (
        <Fragment>
            <span className="wait__time">
                {amount} {plural(unit, amount)}
            </span>
            {postfix}
        </Fragment>
    );
};
