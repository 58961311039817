import React, { FC } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import CopyrightPage from "./CopyrightPage";
import Header from "./Header";
import RandomPornPage from "./RandomPornPage";

const Routes: FC = () => {
    return (
        <Router>
            <div className="container">
                <Header />
                <Route path="/" exact component={RandomPornPage} />
                <Route path="/random-porn" exact component={RandomPornPage} />
                <Route path="/copyright" exact component={CopyrightPage} />
            </div>
        </Router>
    );
};

export default Routes;
