export const calculateDelayMinTotalTime = 6000;
export const calculateDelayMaxTotalTime = 10000;
export const calculateDelayMinDelay = 100;
export const calculateDelayMaxDelay = 750;

export const redirectDelay = 1500;

// export const secondsToWait = 60 * 60;
export const secondsToWait = -1;
// export const secondsToWait = 1;

export const numberOfPreviousPicksToRemember = 10;
