export const toSessionStorage = toStorage(sessionStorage);
export const fromSessionStorage = fromStorage(sessionStorage);
export const updateSessionStorage = updateStorage(sessionStorage);
export const deleteInSessionStorage = deleteInStorage(sessionStorage);

export const toLocalStorage = toStorage(localStorage);
export const fromLocalStorage = fromStorage(localStorage);
export const updateLocalStorage = updateStorage(localStorage);
export const deleteInLocalStorage = deleteInStorage(localStorage);

function toStorage(storage: Storage) {
    return (key: string, thing: any) => {
        try {
            storage.setItem(key, JSON.stringify(thing));
        } catch (error) {}
    };
}

function fromStorage(storage: Storage) {
    return (key: string) => {
        try {
            const savedValue = storage.getItem(key);
            if (savedValue === null) return null;
            else return JSON.parse(savedValue);
        } catch (e) {
            return null;
        }
    };
}

function updateStorage(storage: Storage) {
    return (key: string, updater: (a: any) => any) => {
        const existingValue = fromStorage(storage)(key);
        const updated = updater(existingValue);
        toStorage(storage)(key, updated);
        return updated;
    };
}

function deleteInStorage(storage: Storage) {
    return (key: string) => {
        try {
            storage.removeItem(key);
        } catch (error) {}
    };
}
