import { observer } from "mobx-react";
import React, { FC, Fragment, memo } from "react";
import { Link } from "react-router-dom";
import CelebrityPickerState from "../state/CelebrityPickerState";
import Wait from "./Wait";

const celebrityPicker = new CelebrityPickerState();

const CelebrityPicker: FC = observer(() => {
    const {
        isRolling: isPicking,
        isFinished,
        currentGallery,
        isAllowedToRoll
    } = celebrityPicker;

    const celebrityName = (currentGallery && currentGallery.name)!;

    if (!isAllowedToRoll) {
        return <Wait celebrityPicker={celebrityPicker} />;
    } else if (isFinished) {
        return <PickedCelebrity name={celebrityName} />;
    } else if (isPicking) {
        return <PickingCelebrity name={celebrityName} />;
    } else {
        return (
            <Fragment>
                <RandomButton celebrityPicker={celebrityPicker} />
                <Wait celebrityPicker={celebrityPicker} />
            </Fragment>
        );
    }
});

export default CelebrityPicker;

const RandomButton: FC<{
    celebrityPicker: CelebrityPickerState;
}> = observer(({ celebrityPicker }) => {
    const { startPicker } = celebrityPicker;

    return (
        <button className="random-button" onClick={startPicker}>
            Random Celeb
        </button>
    );
});

const PickedCelebrity: FC<{
    name: string;
}> = ({ name }) => {
    return (
        <div className="random-celeb random-celeb--picked">
            <div className="random-celeb__title">You got</div>
            <div className="random-celeb__name">{name}</div>
            <span className="random-celeb__status">
                Redirecting
                <Ellipsis />
            </span>
        </div>
    );
};

const PickingCelebrity: FC<{
    name: string;
}> = ({ name }) => {
    return (
        <div className="random-celeb">
            <span className="random-celeb__status">
                Rolling
                <Ellipsis />
            </span>
            <div className="random-celeb__name">{name}</div>
        </div>
    );
};

export const Ellipsis: FC = memo(() => {
    return (
        <div className="ellipsis">
            <div className="ellipsis__dot" />
            <div className="ellipsis__dot" />
            <div className="ellipsis__dot" />
        </div>
    );
});
