import { CelebrityGallery } from "./redditApi";

const backupCelebrityNames = [
    "Alexandra Daddario",
    "Alice Eve",
    "Alison Brie",
    "Amanda Cerny",
    "Amanda Seyfried",
    "Amber Heard",
    "Amy Adams",
    "Ana De Armas",
    "Angelina Jolie",
    "Anna Kendrick",
    "Anne Hathaway",
    "Ariana Grande",
    "Ariel Winter",
    "Ashley Graham",
    "Bella Thorne",
    "Brie Larson",
    "Cameron Diaz",
    "Cameron Richardson",
    "Camila Cabello",
    "Cara Delevingne",
    "Carice Van Houten",
    "Charlotte McKinney",
    "Chloë Grace Moretz",
    "Christina Hendricks",
    "Daisy Ridley",
    "Demi Lovato",
    "Elizabeth Olsen",
    "Emilia Clarke",
    "Emily Blunt",
    "Emily Ratajkowski",
    "Emma Stone",
    "Emma Watson",
    "Emmy Rossum",
    "Eva Green",
    "Eva Mendes",
    "Fergie",
    "Gal Gadot",
    "Gwyneth Paltrow",
    "Halle Berry",
    "Hayley Atwell",
    "Heather Graham",
    "Iggy Azalea",
    "Iskra Lawrence",
    "Isla Fischer",
    "Jenna Fischer",
    "Jennifer Aniston",
    "Jennifer Connelly",
    "Jennifer Lawrence",
    "Jennifer Lopez",
    "Jennifer Love Hewitt",
    "Jessica Alba",
    "Kaley Cuoco",
    "Kate Beckinsale",
    "Kate Upton",
    "Kate Winslet",
    "Katy Mixon",
    "Katy Perry",
    "Kelly Brook",
    "Kim Kardashian",
    "Kristen Bell",
    "Kristen Stewart",
    "Kylie Jenner",
    "Lake Bell",
    "Laura Wiggins",
    "Leah Remini",
    "Lia Marie Johnson",
    "Lily James",
    "Lindsay Lohan",
    "Margot Robbie",
    "May Elizabeth Winstead",
    "Megan Fox",
    "Melissa Benoist",
    "Melissa Rauch",
    "Miley Cyrus",
    "Morena Baccarin",
    "Natalie Dormer",
    "Natalie Portman",
    "Nicki Minaj",
    "Penelope Cruz",
    "Rachel McAdams",
    "Reese Whiterspoon",
    "Rihanna",
    "Rose Leslie",
    "Salma Hayek",
    "Sarah Hyland",
    "Scarlett Johansson",
    "Selena Gomez",
    "Sofia Vergara",
    "Taylor Swift",
    "Victoria Justice"
];

const backupCelebrityGalleries: CelebrityGallery[] = backupCelebrityNames.map(
    name => ({
        name,
        url: null,
        adUrl: null
    })
);

export default backupCelebrityGalleries;
