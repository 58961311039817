import { action, computed, observable } from "mobx";
import { CelebrityGallery } from "../api/redditApi";
import { redirect } from "../utilities/redirect";
import {
    calculateDelay,
    getSecondsUntillNextRoll,
    saveRoll,
    getRandomTargetRollTime
} from "../utilities/roll";
import { getRandomGallery, pickFinalGallery } from "../actions/galleryActions";
import { redirectDelay } from "../constants";

enum PickerStatus {
    PENDING,
    ROLLING,
    FINISHED
}

export default class CelebrityPickerState {
    @observable.ref currentGallery: CelebrityGallery | null = null;
    @observable status = PickerStatus.PENDING;
    @observable startTime: number | null = null;
    @observable isAdRoll = false;

    targetRollTime: number = 0;

    @observable secondsUntilLegalRoll = getSecondsUntillNextRoll();

    constructor() {
        setInterval(this.recalculateSecondsUntilLegalRoll, 1000);
    }

    @computed get isRolling() {
        return this.status === PickerStatus.ROLLING;
    }

    @computed get isFinished() {
        return this.status === PickerStatus.FINISHED;
    }

    @computed get isAllowedToRoll() {
        return (
            this.isRolling ||
            this.isFinished ||
            this.secondsUntilLegalRoll < 1 ||
            this.isAdRoll
        );
    }

    @action.bound recalculateSecondsUntilLegalRoll() {
        this.secondsUntilLegalRoll = getSecondsUntillNextRoll();
    }

    @action.bound startPicker() {
        this.startTime = Date.now();
        this.targetRollTime = getRandomTargetRollTime();
        this.status = PickerStatus.ROLLING;
        this.pickTemporaryCeleb();
        this.pickAfterTimeout();
        saveRoll();
    }

    @action.bound startPickerWithAds() {
        this.isAdRoll = true;
        this.startPicker();
    }

    @action.bound pickAfterTimeout() {
        const elapsed = Date.now() - this.startTime!;
        const delay = calculateDelay(this.targetRollTime, elapsed);
        setTimeout(() => {
            if (Date.now() - this.startTime! >= this.targetRollTime) {
                this.tryToFinishPicking();
            } else {
                this.pickTemporaryCeleb();
                this.pickAfterTimeout();
            }
        }, delay);
    }

    @action.bound tryToFinishPicking() {
        const redirectOrKeepGoing = (url: URL | null) => {
            if (url === null) {
                this.pickAfterTimeout();
            } else {
                this.status = PickerStatus.FINISHED;
                redirect(url, redirectDelay);
            }
        };

        const { url, adUrl } = this.pickFinalCeleb();
        if (this.isAdRoll) {
            redirectOrKeepGoing(adUrl);
        } else {
            redirectOrKeepGoing(url);
        }
    }

    @action.bound pickTemporaryCeleb() {
        const gallery = getRandomGallery();
        this.currentGallery = gallery;
    }

    @action.bound pickFinalCeleb(): CelebrityGallery {
        const gallery = pickFinalGallery();
        this.currentGallery = gallery;
        return gallery;
    }
}
