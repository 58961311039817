import React, { FC, useCallback, useEffect, useState } from "react";
import { redirect } from "../utilities/redirect";
import { Ellipsis } from "./RandomCelebrities";
import ShowIf from "./ShowIf";
import CopyrightLink from "./CopyrightLink";

const RandomPornPage: FC = () => {
    const [redirectUrl, setRedirectUrl] = useState<string | null>(null);
    return (
        <div className="container__content">
            <ShowIf isVisible={redirectUrl !== null}>
                <Countdown url={redirectUrl!} />
            </ShowIf>
            <ShowIf isHidden={redirectUrl !== null}>
                <div className="random-choices">
                    <div className="random-choices__title">Pick a random:</div>
                    <div className="random-choices__buttons">
                        <button
                            className="random-button"
                            onClick={() =>
                                setRedirectUrl("https://www.pornhub.com/random")
                            }
                        >
                            Porn Video
                        </button>
                        <button
                            className="random-button"
                            onClick={() =>
                                setRedirectUrl("https://old.reddit.com/r/randnsfw/")
                            }
                        >
                            NSFW Subreddit
                        </button>
                         <button
                            className="random-button"
                            onClick={() =>
                                setRedirectUrl("https://chaturbate.com/in/?track=default&tour=bfpW&campaign=K1HVL")
                            }
                        >
                            Chaturbate (female)
                        </button>
                    </div>
                </div>
                <CopyrightLink />
            </ShowIf>
        </div>
    );
};

export default RandomPornPage;

interface CountdownProps {
    url: string;
}

const Countdown: FC<CountdownProps> = ({ url }) => {
    const [number, setNumber] = useState(5);

    useEffect(() => {
        if (number === 1) {
            redirect(new URL(url), 800);
        } else if (number > 1) {
            setTimeout(() => {
                setNumber(number - 1);
            }, 1000);
        }
    }, [number]);

    return (
        <div className="random-porn">
            <div className="random-porn__title">
                Redirecting in
                <Ellipsis></Ellipsis>
            </div>
            <div className="random-porn__number">{number}</div>
        </div>
    );
};
