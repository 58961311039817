import { CelebrityGallery, fetchGalleries } from "../api/redditApi";
import { observable, computed, action, runInAction } from "mobx";
import backupCelebrityGalleries from "../api/backupCelebrityGalleries";

export default class CelebrityGalleriesState {
    @observable.ref private _galleries: CelebrityGallery[] | null = null;

    @computed get successfullyFetched() {
        return this._galleries !== null;
    }

    @computed get galleries() {
        if (this._galleries === null) return backupCelebrityGalleries;
        else return this._galleries;
    }

    @action private async fetchGalleries() {
        try {
            const galleries = await fetchGalleries();
            if (galleries.length === 0) {
                throw new Error("Gallery list is empty");
            }

            runInAction(() => {
                this._galleries = galleries;
            });
        } catch (e) {
            alert("Failed to fetch galleries: " + e.message);
        }
    }

    constructor() {
        this.fetchGalleries();
    }
}
