import {
    secondsToWait,
    calculateDelayMinDelay,
    calculateDelayMaxDelay,
    calculateDelayMinTotalTime,
    calculateDelayMaxTotalTime
} from "../constants";
import { fromLocalStorage, toLocalStorage } from "./storage";

const storageKey = "JerkOffToCelebs:rolls-v1";

const savedRolls = readRolls();

export function readRolls(): number[] {
    return fromLocalStorage(storageKey) || [];
}

export function saveRoll() {
    savedRolls.push(Date.now());
    toLocalStorage(storageKey, savedRolls);
}

export function getSecondsUntillNextRoll(): number {
    const lastRoll = savedRolls[savedRolls.length - 1];
    if (lastRoll === undefined) return 0;
    else return secondsToWait - (Date.now() - lastRoll) / 1000;
}

export interface Time {
    seconds: number;
    minutes: number;
    hours: number;
}

export function timeFromSeconds(totalSeconds: number): Time {
    const hoursToSeconds = (hours: number) => hours * 60 * 60;
    const minutesToSeconds = (minutes: number) => minutes * 60;

    let hours = Math.floor(totalSeconds / 60 / 60);
    let minutes = Math.floor((totalSeconds - hoursToSeconds(hours)) / 60);
    let seconds = Math.floor(
        totalSeconds - minutesToSeconds(minutes) - hoursToSeconds(hours)
    );

    return {
        seconds,
        minutes,
        hours
    };
}

export interface Rolls {
    rollsLastHour: number;
    rollsLastDay: number;
}

export function getRolls(): Rolls {
    const now = Date.now();
    const hourInMillis = 1000 * 60 * 60;
    const dayInMillis = hourInMillis * 24;

    let rollsLastHour = 0;
    let rollsLastDay = 0;

    for (let time of savedRolls) {
        const elapsed = now - time;
        if (elapsed <= hourInMillis) {
            rollsLastHour++;
        }
        if (elapsed <= dayInMillis) {
            rollsLastDay++;
        }
    }

    return {
        rollsLastDay,
        rollsLastHour
    };
}

export function calculateDelay(totalTime: number, elapsed: number) {
    const minDelay = calculateDelayMinDelay;
    const maxDelay = calculateDelayMaxDelay;

    const safeElapsed = Math.max(Math.min(elapsed, totalTime), 0);
    const done = Math.pow(safeElapsed / totalTime, 3);

    const delay = (maxDelay - minDelay) * done + minDelay;

    return delay;
}

export function getRandomTargetRollTime() {
    const timeSpan = calculateDelayMaxTotalTime - calculateDelayMinTotalTime;
    return Math.random() * timeSpan + calculateDelayMinTotalTime;
}
